import { isNull, mergeWith, isEmpty } from 'lodash-es'
import { SET_INFO_SUCCESS } from '../info/mutations';
import {
	ACTION_AUTHENTICATE_SELLER,
	SET_SALE_INFORMATION,
	CLEAR_SERVICE_HISTORY_DETAIL,
	SET_CONDITION_INFO,
	CLEAR_SALE_INFO_OPTION,
	SET_CAR_INFO,
	CLEAR_INFO,
	CLEAR_INFO_EXTEND,
	CLEAR_INFO_MODEL,
	CLEAR_INFO_YEAR,
	CLEAR_INFO_BODY_TYPES,
	CLEAR_INFO_FUEL_TYPES,
	CLEAR_INFO_DRIVE_TYPES,
	CLEAR_INFO_TRANS,
	CLEAR_INFO_SERIES,
	CLEAR_INFO_ENGINE_TYPES,
	CLEAR_AUTHENTICATE_DATA,
	UPDATE_REGO_AND_STATE,
	REGO_RE_LOOKUP,
	UPDATE_DATA,
	CLEAR_SERVICE_HISTORY_NOTES,
	SET_WRITTEN_OFF_AND_STOLEN,
	CLEAR_INFO_ENGINE_SIZES,
	CLEAR_INFO_NUMBER_OF_SEATS,
	REGO_ADDITIONAL_LOOKUP,
	SET_LOCATION_ID,
	SAVE_ACTION_LOG,
	SET_GUIDED_CAMERA_MOBILE_NUMBER,
	SET_GUIDED_CAMERA_SMS_URL,
	SET_DELETED_IMAGES,
	SET_PRODUCT_TIER,
} from "./actions";
import {
	ACTION_AUTHENTICATE_SELLER_SUCCESS,
	SET_SALE_INFORMATION_SUCCESS,
	CLEAR_SERVICE_HISTORY_DETAIL_SUCCESS,
	SET_CONDITION_INFO_SUCCESS,
	CLEAR_SALE_INFO_OPTION_SUCCESS,
	SET_CAR_INFO_SUCCESS,
	CLEAR_INFO_SUCCESS,
	CLEAR_INFO_EXTEND_SUCCESS,
	CLEAR_INFO_MODEL_SUCCESS,
	CLEAR_INFO_YEAR_SUCCESS,
	CLEAR_INFO_BODY_TYPES_SUCCESS,
	CLEAR_INFO_FUEL_TYPES_SUCCESS,
	CLEAR_INFO_DRIVE_TYPES_SUCCESS,
	CLEAR_INFO_TRANS_SUCCESS,
	CLEAR_INFO_SERIES_SUCCESS,
	CLEAR_INFO_ENGINE_TYPES_SUCCESS,
	CLEAR_AUTHENTICATE_DATA_SUCCESS,
	UPDATE_REGO_AND_STATE_SUCCESS,
	REGO_RE_LOOKUP_SUCCESS,
	CLEAR_SERVICE_HISTORY_NOTES_SUCCESS,
	SET_WRITTEN_OFF_AND_STOLEN_SUCCESS,
	CLEAR_INFO_ENGINE_SIZES_SUCCESS,
	CLEAR_INFO_NUMBER_OF_SEATS_SUCCESS,
	REGO_ADDITIONAL_LOOKUP_SUCCESS,
	SET_LOCATION_ID_SUCCESS,
	SET_GUIDED_CAMERA_MOBILE_NUMBER_SUCCESS,
	SET_GUIDED_CAMERA_SMS_URL_SUCCESS,
	SET_DELETED_IMAGES_SUCCESS,
	SET_PRODUCT_TIER_SUCCESS,
} from "./mutations";
import LeadUpdateService from "@/services/lead-update";
import customerDetailService from "@/services/customer-detail";
import {
	getDeviceInfo,
	getParamFromURL,
	removeFormatNumberWithComma,
	separatesUploadImages,
} from "@/helper/utils";

const initialState = {
	email: '',
	rego: '',
	appraisalId: '',
	imageUrls: [],
	make: '',
	model: '',
	year: '',
	customerName: '',
	customerMobile: '',
	customerPostalCode: '',
	listed: false,
	leadSource: '',
	leadId: '',
	conditionInfo: {
		serviceHistory: '',
		serviceHistoryDetail: '',
		serviceHistoryNotes: '',
		tyreReplacement: '',
		description: '',
		majorAccident: '',
		hailOrFlooding: '',
		warningLights: '',
		kms: '',
		key: '',
		outstandingFinance: '',
		interior: null,
		exterior: null,
		warningLightsDesc: '',
		warranty: '',
		registrationStatus: '',
	},
	carInfo: {
		location: "",
		make: "",
		model: "",
		year: "",
		color: "",
		badgeEdition: "",
		bodyType: "",
		fuelType: "",
		drive: "",
		transmission: "",
		series: "",
		model_year: "",
		engineType: "",
		badgeEditions: [],
		buildDate: "",
		relevantRBDesc: "",
		badge: "",
		edition: "",
		engineSize: "",
		numberOfSeat: "",
	},
	saleInfo: {
		sell: '',
		pickUp: '',
		sellerTermsAndFees: false,
		marketingComm: false,
		reservePrice: '',
		isSubscribedToMailchimp: false,
		advertised: '',
		platform: [],
		productTier: '',
	},
	readonlyMode: false,
	funnel: '',
	labels: [],
	writtenOff: '',
	stolen: '',
	lookupFailures: [],
	locid: '',
	guidedCameraMobileNumber: '',
	guidedCameraSMSUrl: '',
	deletedImages: [],
	rbcs: [],
	listingStatus: '',
	isSaveTemp: false,
}

const state = { ...initialState };

const getters = {
	email(state) {
		return state.email;
	},
	rego(state) {
		return state.rego;
	},
	appraisalId(state) {
		return state.appraisalId;
	},
	imageUrls(state) {
		return state.imageUrls;
	},
	make(state) {
		return state.make;
	},
	model(state) {
		return state.model;
	},
	year(state) {
		return state.year;
	},
	customerName(state) {
		return state.customerName;
	},
	customerMobile(state) {
		return state.customerMobile;
	},
	customerPostalCode(state) {
		return state.customerPostalCode;
	},
	listed(state) {
		return state.listed;
	},
	conditionInfo(state) {
		return state.listed;
	},
	carInfo(state) {
		return state.carInfo;
	},
	saleInfo(state) {
		return state.saleInfo;
	},
	leadSource(state) {
		return state.leadSource;
	},
	leadId(state) {
		return state.leadId;
	},
	readonlyMode(state) {
		return state.readonlyMode;
	},
	funnel(state) {
		return state.funnel;
	},
	labels(state) {
		return state.labels;
	},
	guidedCameraMobileNumber(state) {
		return state.guidedCameraMobileNumber;
	},
	guidedCameraSMSUrl(state) {
		return state.guidedCameraSMSUrl;
	},
}
const setters = {
	setBadgeEdition(state, payload) {
		let arr = [];
		payload.data.badges.forEach((badge, index) => {
			if (badge === '') {
				arr.push({
					key: `N/A`,
					value: `N/A|N/A`
				})
			} else {
				arr.push({
					key: `${badge} ${payload.data.editions[index]}`,
					value: `${badge}|${payload.data.editions[index]}`
				})
			}
		});
		state.badgeEditions = arr;
		if (state.badgeEditions.length >= 2) {
			state.badgeEditions = [...state.badgeEditions, { key: "Other", value: "Other|Other"}, { key: "Not sure", value: "Not sure|Not sure" }];
		} else {
			state.badgeEditions = [...state.badgeEditions, { key: "Other", value: "Other|Other"}];
		}
		if (state.badgeEditions.length === 1 && state.badgeEditions[0].value !== state.badgeEdition) {
			state.badgeEdition = state.badgeEditions[0].value;
		} else if (
			!state.badgeEditions.some((item) => item.value === state.badgeEdition) &&
			state.badgeEdition !== "Other|Other" &&
			state.badgeEdition !== "Not sure|Not sure"
		) {
			state.badgeEdition = "";
		}
	}
}
const actions = {
	async [ACTION_AUTHENTICATE_SELLER](context, payload) {
		context.commit(ACTION_AUTHENTICATE_SELLER_SUCCESS, payload);
	},
	async [SET_CAR_INFO](context, payload) {
		context.commit(SET_CAR_INFO_SUCCESS, payload);
	},
	async [SET_CONDITION_INFO](context, payload) {
		context.commit(SET_CONDITION_INFO_SUCCESS, payload);
	},
	[CLEAR_SERVICE_HISTORY_DETAIL](context) {
		context.commit(CLEAR_SERVICE_HISTORY_DETAIL_SUCCESS)
	},
	[CLEAR_SERVICE_HISTORY_NOTES](context) {
		context.commit(CLEAR_SERVICE_HISTORY_NOTES_SUCCESS)
	},
	async [SET_SALE_INFORMATION](context, payload) {
		context.commit(SET_SALE_INFORMATION_SUCCESS, payload);
	},
	[CLEAR_SALE_INFO_OPTION](context, payload) {
		context.commit(CLEAR_SALE_INFO_OPTION_SUCCESS, payload);
	},
	[CLEAR_INFO](context) {
		context.commit(CLEAR_INFO_SUCCESS)
	},
	[CLEAR_INFO_EXTEND](context) {
		context.commit(CLEAR_INFO_EXTEND_SUCCESS)
	},
	[CLEAR_INFO_MODEL](context) {
		context.commit(CLEAR_INFO_MODEL_SUCCESS)
	},
	[CLEAR_INFO_YEAR](context) {
		context.commit(CLEAR_INFO_YEAR_SUCCESS)
	},
	[CLEAR_INFO_BODY_TYPES](context) {
		context.commit(CLEAR_INFO_BODY_TYPES_SUCCESS)
	},
	[CLEAR_INFO_FUEL_TYPES](context) {
		context.commit(CLEAR_INFO_FUEL_TYPES_SUCCESS)
	},
	[CLEAR_INFO_DRIVE_TYPES](context) {
		context.commit(CLEAR_INFO_DRIVE_TYPES_SUCCESS)
	},
	[CLEAR_INFO_TRANS](context) {
		context.commit(CLEAR_INFO_TRANS_SUCCESS)
	},
	[CLEAR_INFO_SERIES](context) {
		context.commit(CLEAR_INFO_SERIES_SUCCESS)
	},
	[CLEAR_INFO_ENGINE_TYPES](context) {
		context.commit(CLEAR_INFO_ENGINE_TYPES_SUCCESS)
	},
	[CLEAR_INFO_ENGINE_SIZES](context) {
		context.commit(CLEAR_INFO_ENGINE_SIZES_SUCCESS)
	},
	[CLEAR_INFO_NUMBER_OF_SEATS](context) {
		context.commit(CLEAR_INFO_NUMBER_OF_SEATS_SUCCESS)
	},
	[CLEAR_AUTHENTICATE_DATA](context) {
		context.commit(CLEAR_AUTHENTICATE_DATA_SUCCESS);
	},
	[UPDATE_REGO_AND_STATE](context, payload) {
		context.commit(UPDATE_REGO_AND_STATE_SUCCESS, payload)
	},
	[REGO_RE_LOOKUP](context, payload) {
		context.commit(REGO_RE_LOOKUP_SUCCESS, payload);
		context.commit(SET_INFO_SUCCESS, payload);
	},
	[REGO_ADDITIONAL_LOOKUP](context, payload) {
		context.commit(REGO_ADDITIONAL_LOOKUP_SUCCESS, payload);
	},
	[UPDATE_DATA](context, payload) {
		if (context.rootState.common.onEditPage) {
			return new Promise((resolve, reject) => {
				const { images, oldImages } = separatesUploadImages(context.rootState.photo.uploadImage);
				const saveCarDetailReq = {
					...payload,
					images,
					oldImages,
					deletedImages: payload.deletedImages,
					customerEmail: payload.email,
					conditionInfo: {
						...payload.conditionInfo,
						kms: removeFormatNumberWithComma(payload.conditionInfo.kms),
						key: payload.conditionInfo.key === '3 or more' ? '3' : payload.conditionInfo.key,
						serviceHistoryNotes: payload.conditionInfo.serviceHistory === 'Partial' ? payload.conditionInfo.serviceHistoryNotes : '',
						warningLightsDesc: payload.conditionInfo.warningLights === 'Yes' ? payload.conditionInfo.warningLightsDesc : '',
					},
					saleInfo: {
						...payload.saleInfo,
						reservePrice: parseInt(payload.saleInfo.reservePrice) || 0,
					},
					photoUploadActionLogs: {
						updatedAt: new Date().toISOString(),
						totalCount: {
							add: images.length,
							delete: 0
						}
					},
					isSaveTemp: true,
					usedGuidedCamera: context.rootState.photo.takePhotoWithGuidedCamera,
					uxeId: context.rootState.commonVariables.uxeId,
					ipAddress: payload.ipAddress
				};
				LeadUpdateService.saveTempData(saveCarDetailReq).then(() => {
					resolve();
				}).catch(err => reject(err))
			})
		}
	},
	[SET_WRITTEN_OFF_AND_STOLEN](context, payload) {
		context.commit(SET_WRITTEN_OFF_AND_STOLEN_SUCCESS, payload);
	},
	[SET_LOCATION_ID](context, payload) {
		context.commit(SET_LOCATION_ID_SUCCESS, payload);
	},
	[SAVE_ACTION_LOG](context, payload) {
		return new Promise((resolve, reject) => {
			const deviceInfo = getDeviceInfo()
			const saveActionLogReq = {
				label: "My Car login error",
				leadSource: "lp010",
				data: {
					email: payload.email || "",
					rego: payload.rego || "",
					appraisalId: payload.appraisalId,
				},
				type: "err",
				action: "Rego Search",
				source: getParamFromURL(payload.url, "utm_source") || "",
				device: deviceInfo.device.type,
				operatingSystem: `${deviceInfo.os.name} ${deviceInfo.os.version}`,
				browser: deviceInfo.client.name,
				url: payload.url,
			}
			customerDetailService.saveActionLog(saveActionLogReq).then(() => {
				resolve();
			}).catch(err => reject(err))
		})
	},
	[SET_GUIDED_CAMERA_MOBILE_NUMBER](context, payload) {
		context.commit(SET_GUIDED_CAMERA_MOBILE_NUMBER_SUCCESS, payload);
	},
	[SET_GUIDED_CAMERA_SMS_URL](context, payload) {
		context.commit(SET_GUIDED_CAMERA_SMS_URL_SUCCESS, payload);
	},
	[SET_DELETED_IMAGES](context, payload) {
		context.commit(SET_DELETED_IMAGES_SUCCESS, payload);
	},
	[SET_PRODUCT_TIER](context, payload) {
		context.commit(SET_PRODUCT_TIER_SUCCESS, payload);
	},
}
const mutations = {
	[ACTION_AUTHENTICATE_SELLER_SUCCESS](state, payload) {
		if (isEmpty(payload)) {
			Object.keys(initialState).forEach(key => {
				state[key] = initialState[key];
			});
		} else {
			const activeDeletedImages = payload.deletedImages.filter(image => payload.imageUrls.includes(image));
			state.email = payload.email;
			state.rego = payload.rego;
			state.appraisalId = payload.appraisalId;
			state.leadSource = payload.leadSource;
			state.leadId = payload.leadId;
			state.imageUrls = payload.imageUrls;
			state.make = payload.make;
			state.model = payload.model;
			state.year = payload.year;
			state.customerName = payload.customerName;
			state.customerMobile = payload.customerMobile;
			state.customerPostalCode = payload.customerPostalCode;
			state.listed = payload.listed;
			state.conditionInfo = mergeWith({}, state.conditionInfo, payload.conditionInfo, (o, s) => isNull(s) ? o : s);
			state.carInfo = mergeWith(
				{},
				state.carInfo,
				{ ...payload.carInfo, 
					badgeEdition: payload.carInfo.badgeEdition !== '' 
						? `${payload.carInfo.badgeEdition}${payload.carInfo.badgeEdition.indexOf('|') === -1 ? '|' : ''}`
						: ''
				},
				(o, s) => (isNull(s) ? o : s)
			);
			state.saleInfo = mergeWith(
				{},
				state.saleInfo,
				{
					...payload.saleInfo,
					reservePrice:
					payload.saleInfo.reservePrice !== 0
						? payload.saleInfo.reservePrice
						: null,
				},
				(o, s) => (isNull(s) ? o : s)
			);
			const oldCarDetails = JSON.parse(JSON.stringify(payload));
			state.oldCarDetails = {
				...oldCarDetails,
				customerEmail: payload.email,
				conditionInfo: {
					...oldCarDetails.conditionInfo,
					hailOrFlooding: oldCarDetails.conditionInfo.hailOrFlooding === null ? '' : oldCarDetails.conditionInfo.hailOrFlooding,
					majorAccident: oldCarDetails.conditionInfo.majorAccident === null ? '' : oldCarDetails.conditionInfo.majorAccident,
					outstandingFinance: oldCarDetails.conditionInfo.outstandingFinance === null ? '' : oldCarDetails.conditionInfo.outstandingFinance,
					warningLights: oldCarDetails.conditionInfo.warningLights === null ? '' : oldCarDetails.conditionInfo.warningLights,
				},
				deletedImages: activeDeletedImages,
			};
			state.readonlyMode = payload.readonlyMode;
			state.funnel = payload.funnel;
			state.labels = payload.labels;
			state.locid = payload.locid;
			state.stolen = payload.stolen;
			state.writtenOff = payload.writtenOff;
			state.deletedImages = activeDeletedImages;
			state.listingStatus = payload.listingStatus;
			state.isSaveTemp = false;
		}
	},
	[SET_CAR_INFO_SUCCESS](state, payload) {
		state.carInfo = mergeWith({}, state.carInfo, payload, (o, s) => isNull(s) ? o : s);
	},
	[SET_CONDITION_INFO_SUCCESS](state, payload) {
		state.conditionInfo = mergeWith({}, state.conditionInfo, payload, (o, s) => isNull(s) ? o : s);
	},
	[CLEAR_SERVICE_HISTORY_DETAIL_SUCCESS](state) {
		state.conditionInfo = {
			...state.conditionInfo,
			serviceHistoryDetail: '',
		}
	},
	[CLEAR_SERVICE_HISTORY_NOTES_SUCCESS](state) {
		state.conditionInfo = {
			...state.conditionInfo,
			serviceHistoryNotes: '',
		}
	},
	[SET_SALE_INFORMATION_SUCCESS](state, payload) {
		state.saleInfo = mergeWith({}, state.saleInfo, payload, (o, s) => isNull(s) ? o : s);
	},
	[CLEAR_SALE_INFO_OPTION_SUCCESS](state, payload) {
		state.saleInfo = {
			...state.saleInfo,
			[payload]: payload === 'platform' ? [] : '',
		}
	},
	[CLEAR_INFO_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			make: '',
			model: '',
			badgeEdition: '',
			year: '',
			fuelType: '',
			bodyType: '',
			series: '',
			engineType: '',
			drive: '',
			transmission: '',
			shift: '',
			engineSize: '',
			numberOfSeat: '',
		}
	},
	[CLEAR_INFO_EXTEND_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			badgeEdition: '',
			fuelType: '',
			bodyType: '',
			series: '',
			engineType: '',
			drive: '',
			transmission: '',
			shift: '',
			model_year: '',
			engineSize: '',
			numberOfSeat: '',
		}
	},
	[CLEAR_INFO_MODEL_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			model: '',
			year: '',
			badgeEdition: '',
			fuelType: '',
			bodyType: '',
			series: '',
			engineType: '',
			drive: '',
			transmission: '',
			shift: '',
			model_year: '',
			engineSize: '',
			numberOfSeat: '',
		}
	},
	[CLEAR_INFO_YEAR_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			year: '',
			badgeEdition: '',
			fuelType: '',
			bodyType: '',
			series: '',
			engineType: '',
			drive: '',
			transmission: '',
			shift: '',
			model_year: '',
			engineSize: '',
			numberOfSeat: '',
		}
	},
	[CLEAR_INFO_BODY_TYPES_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			bodyType: '',
			fuelType: '',
			drive: '',
			transmission: '',
			shift: '',
			series: '',
			engineType: '',
			model_year: '',
			engineSize: '',
			numberOfSeat: '',
		}
	},
	[CLEAR_INFO_FUEL_TYPES_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			fuelType: '',
			drive: '',
			transmission: '',
			shift: '',
			series: '',
			engineType: '',
			model_year: '',
			engineSize: '',
			numberOfSeat: '',
		}
	},
	[CLEAR_INFO_DRIVE_TYPES_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			drive: '',
			transmission: '',
			shift: '',
			series: '',
			engineType: '',
			model_year: '',
			engineSize: '',
			numberOfSeat: '',
		}
	},
	[CLEAR_INFO_TRANS_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			transmission: '',
			shift: '',
			series: '',
			engineType: '',
			model_year: '',
			engineSize: '',
			numberOfSeat: '',
		}
	},
	[CLEAR_INFO_SERIES_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			series: '',
			engineType: '',
			model_year: '',
			engineSize: '',
			numberOfSeat: '',
		}
	},
	[CLEAR_INFO_ENGINE_TYPES_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			engineType: '',
			model_year: '',
			engineSize: '',
			numberOfSeat: '',
		}
	},
	[CLEAR_INFO_ENGINE_SIZES_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			engineSize: '',
			numberOfSeat: '',
			model_year: '',
		}
	},
	[CLEAR_INFO_NUMBER_OF_SEATS_SUCCESS](state) {
		state.carInfo = {
			...state.carInfo,
			numberOfSeat: '', 
			model_year: '',
		}
	},
    [CLEAR_AUTHENTICATE_DATA_SUCCESS](state) {
		state.carInfo = initialState.carInfo;
		state.conditionInfo = initialState.conditionInfo;
		state.saleInfo = initialState.saleInfo;
	},
	[UPDATE_REGO_AND_STATE_SUCCESS](state, payload) {
		state.rego = payload.regoNumber;
		state.carInfo.location = payload.state;
	},
	[REGO_RE_LOOKUP_SUCCESS](state, payload) {
		state.make = payload.make;
		state.model = payload.model;
		state.year = payload.year;
		state.carInfo = mergeWith({}, state.carInfo, payload, (o, s) => isNull(s) ? o : s);
		setters.setBadgeEdition(state.carInfo, { data: {
			badges: payload.badges,
			editions: payload.editions,
		}});
		state.rbcs = payload.rbcs;
	},
	[SET_WRITTEN_OFF_AND_STOLEN_SUCCESS](state, payload) {
		state.writtenOff = payload.writtenOff;
		state.stolen = payload.stolen;
		state.lookupFailures = payload.lookupFailures;
	},
	[REGO_ADDITIONAL_LOOKUP_SUCCESS](state, payload) {
		state.carInfo.engineSize = payload.engineSize;
		state.carInfo.numberOfSeat = payload.numberOfSeat;
	},
	[SET_LOCATION_ID_SUCCESS](state, payload) {
		state.locid = payload;
	},
	[SET_GUIDED_CAMERA_MOBILE_NUMBER_SUCCESS](state, payload) {
		state.guidedCameraMobileNumber = payload;
	},
	[SET_GUIDED_CAMERA_SMS_URL_SUCCESS](state, payload) {
		state.guidedCameraSMSUrl = payload;
	},
	[SET_DELETED_IMAGES_SUCCESS](state, payload) {
		state.deletedImages = payload;
	},
	[SET_PRODUCT_TIER_SUCCESS](state, payload) {
		state.saleInfo.productTier = payload;
	},
}

export default { state, actions, mutations, getters };